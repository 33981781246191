import { Link } from 'gatsby'
import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import './registr_trestu.css'

// http://www.dktech.cz/?page_id=28

const RegistrTrestu: FC = () => {
  return (
    <PageTemplate>
      <div className="topTitle">
        <h1><Link to="/registr_trestu">Registr trestů</Link></h1>
      </div>
      <p><strong>Registr trestů</strong></p>
      <table cellSpacing="1" cellPadding="1" className="registr_trestu">
        <tbody>
          <tr>
            <th>Nick postavy</th>
            <th>Porušení pravidla</th>
            <th>Trest</th>
            <th>Uložil</th>
            <th>Konec trestu</th>
          </tr>
          <tr>
            <td><strong>Fuzz3r</strong>, Lina, Strejda, Prcka, NeviemParkovat, kjut</td>
            <td style={{ textAlign: 'center' }}>čl. 2 odst. 5</td>
            <td style={{ textAlign: 'center' }}>čl. 6 odst. 3 písm. d) – ban</td>
            <td style={{ textAlign: 'center' }}>7framm</td>
            <td style={{ textAlign: 'center' }}>perma</td>
          </tr>
          <tr>
            <td><strong>SgtMarmite</strong>, Holkanavsechno</td>
            <td style={{ textAlign: 'center' }}>čl. 2 odst. 5</td>
            <td style={{ textAlign: 'center' }}>čl. 6 odst. 3 písm. d) – ban</td>
            <td style={{ textAlign: 'center' }}>7framm</td>
            <td style={{ textAlign: 'center' }}>perma</td>
          </tr>
          <tr>
            <td><strong>Maugi</strong> + všechni peti</td>
            <td style={{ textAlign: 'center' }}>čl. 2 odst. 5</td>
            <td style={{ textAlign: 'center' }}>čl. 6 odst. 3 písm. d) – ban</td>
            <td style={{ textAlign: 'center' }}>7framm</td>
            <td style={{ textAlign: 'center' }}>perma</td>
          </tr>
          <tr>
            <td><strong>Kundicka</strong></td>
            <td style={{ textAlign: 'center' }}>čl. 2 odst. 3</td>
            <td style={{ textAlign: 'center' }}>čl. 6 odst. 2 písm. d) – jail</td>
            <td style={{ textAlign: 'center' }}>Algy</td>
            <td style={{ textAlign: 'center' }}>31. 12. 2016</td>
          </tr>
          <tr>
            <td><strong>Ponitak</strong>, Ponita, Poni, Tiny, Puresum, Ironwing, Irminia</td>
            <td style={{ textAlign: 'center' }}>čl. 2 odst. 5</td>
            <td style={{ textAlign: 'center' }}>čl. 6 odst. 3 písm. d) – ban</td>
            <td style={{ textAlign: 'center' }}>7framm</td>
            <td style={{ textAlign: 'center' }}>perma</td>
          </tr>
          <tr>
            <td><strong>masterOfArt</strong>, Persea, mAxxEl, Perfect, hooliday, Silvinka, eStRiXxXoVel, eStRiXoVeEeL</td>
            <td style={{ textAlign: 'center' }}>čl. 2 odst. 5</td>
            <td style={{ textAlign: 'center' }}>čl. 6 odst. 3 písm. d) – ban</td>
            <td style={{ textAlign: 'center' }}>7framm</td>
            <td style={{ textAlign: 'center' }}>perma</td>
          </tr>
          <tr>
            <td><strong>Analhazard</strong></td>
            <td style={{ textAlign: 'center' }}>čl. 2 odst. 3</td>
            <td style={{ textAlign: 'center' }}>čl. 6 odst. 2 písm. d) – jail</td>
            <td style={{ textAlign: 'center' }}>Algy</td>
            <td style={{ textAlign: 'center' }}>31. 12. 2016</td>
          </tr>
          <tr>
            <td><strong>Manorka,</strong> Belupo, Gaelic, Upirka + peti</td>
            <td style={{ textAlign: 'center' }}>čl. 1 odst. 3</td>
            <td style={{ textAlign: 'center' }}>čl. 6 odst. 3 písm. d) – ban</td>
            <td style={{ textAlign: 'center' }}>7framm</td>
            <td style={{ textAlign: 'center' }}>perma</td>
          </tr>
          <tr>
            <td><strong>Croix,</strong> Croixer</td>
            <td style={{ textAlign: 'center' }}>čl. 1 odst. 3</td>
            <td style={{ textAlign: 'center' }}>čl. 6 odst. 3 písm. d) – ban</td>
            <td style={{ textAlign: 'center' }}>7framm</td>
            <td style={{ textAlign: 'center' }}>perma</td>
          </tr>
          <tr>
            <td><strong>Lekarnice</strong></td>
            <td style={{ textAlign: 'center' }}>čl. 3 odst. 3</td>
            <td style={{ textAlign: 'center' }}>čl. 6 odst. 2 písm. d) – jail</td>
            <td style={{ textAlign: 'center' }}>Algy</td>
            <td style={{ textAlign: 'center' }}>7 dní – do 11. 5. 2014</td>
          </tr>
          <tr>
            <td>—</td>
            <td style={{ textAlign: 'center' }}>—</td>
            <td style={{ textAlign: 'center' }}>—</td>
            <td style={{ textAlign: 'center' }}>—</td>
            <td style={{ textAlign: 'center' }}>—</td>
          </tr>
        </tbody>
      </table>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default RegistrTrestu

export { Head } from '../components/defaultHtmlHead'